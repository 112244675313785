import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Img, { FluidObject } from "gatsby-image";

import { animationTypes, colors } from "@util/constants";
import { AnimationContainer, IconButton } from "@sub";
import { Container, H2, H3, P } from "@util/standard";
import { SanityImage, SanityBlockContent, SanityLink } from "@graphql-types";
import ShareButton from "./shareButton";

interface Props {
  featureImage: SanityImage | null | undefined;
  name: string | null | undefined;
  creationDate?: string | null | undefined;
  datePeriod?: string;
  description?: string | null | undefined;
  subText?: SanityBlockContent | null | undefined;
  displayStyle: string | null | undefined;
  ctaLink?: SanityLink | null | undefined;
  doubleHeight?: boolean;
  isHistory?: boolean;
  id?: string;
}

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  margin: 10% auto;
`;

const CustomBlockContentContainer = styled.div<{ isDark: boolean }>`
  h2,
  h3,
  h4 {
    font-family: "bold";
    ${({ isDark }) =>
      isDark ? `color: white;` : `color: ${colors.darkGrey}; opacity: 0.5;`};
  }

  p {
    color: white;
  }
`;

function PopUpContentBlock(props: Props) {
  const {
    name,
    featureImage,
    creationDate,
    subText,
    description,
    displayStyle,
    ctaLink,
    doubleHeight,
    isHistory,
    id,
    datePeriod,
  } = props;

  const isDark = displayStyle === "dark";

  return (
    <AnimationContainer animationType={animationTypes.fadeIn} delay={500}>
      <Container overflow="scroll">
        <Container
          margin="auto"
          tabletMargin={"25% auto 0px auto"}
          mobileMargin={"17% auto auto auto"}
          width="58%"
          mobileWidth="75%"
        >
          <H2
            opacity={0.41}
            fontFamily="extra-bold"
            fontSize={doubleHeight ? 17 : 50}
            mobileFontSize={isHistory ? 17 : 30}
            margin="0px"
            color="white"
            mobileLineHeight={isHistory ? 18 : 30}
          >
            {name?.toUpperCase()}
          </H2>
          {creationDate && (
            <H3
              fontSize={doubleHeight ? 14 : 20}
              mobileFontSize={12}
              mobileLineHeight={13}
              margin="5% auto"
              color="white"
              opacity={0.84}
            >
              {creationDate}
            </H3>
          )}
          {description && (
            <P fontSize={14} lineHeight={20} margin="0px" color="white">
              {description}
            </P>
          )}
          {isHistory && <ShareButton id={id} category={datePeriod} isDark />}
          {subText && (
            <CustomBlockContentContainer isDark={isDark}>
              <BlockContent blocks={subText._rawContent} />
              {ctaLink && ctaLink.linktext !== null && (
                <IconButton
                  url={ctaLink.url}
                  buttonText={ctaLink.linktext}
                  darkVersion={!isDark}
                />
              )}
            </CustomBlockContentContainer>
          )}
          <StyledImg fluid={featureImage?.asset?.fluid as FluidObject} />
        </Container>
      </Container>
    </AnimationContainer>
  );
}

export default PopUpContentBlock;
