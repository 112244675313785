import React, { ReactNode } from "react";
import Img, { FluidObject } from "gatsby-image";
import styled, { keyframes } from "styled-components";

import {
  animationTypes,
  colors,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, H2, H3, P } from "@util/standard";
import { AnimationContainer, IconButton, Image, ShareButton } from "@sub";
import { SanityBlockContent, SanityLink, SanityImage } from "@graphql-types";
import BlockContent from "@sanity/block-content-to-react";

interface Props {
  children: ReactNode;
  index: number | null | undefined;
  featureImage: SanityImage | null | undefined;
  name: string | null | undefined;
  creationDate?: string | null | undefined;
  description?: string | null | undefined;
  subText?: SanityBlockContent | null | undefined;
  displayStyle: string | null | undefined;
  ctaLink?: SanityLink | null | undefined;
  doubleHeight?: boolean;
  isHistory?: boolean;
  id?: string;
  datePeriod?: string;
  containImage?: boolean;
}

const ExpandedContainer = styled(Container)<{
  isFirst?: boolean;
  isMiddle?: boolean;
  isThird?: boolean;
  isDark?: boolean;
  doubleHeight?: boolean;
  isHistory?: boolean;
}>`
  margin-top: 3px;
  ${({ doubleHeight }) =>
    doubleHeight ? `height: 199.6%;` : `height: 99.6%;`};
  ${({ isDark, isMiddle }) =>
    isDark && !isMiddle
      ? `width: 299.8%; margin-left: -98.8%;`
      : isDark && isMiddle
      ? `width: 299.8%; margin-left: -99.8%;`
      : `width: 299%; margin-left: -98.7%;`};
  @keyframes swipeIn {
    from {
      width: 0%;
    }
    to {
      width: 299%;
    }
  }
  animation: swipeIn 0.5s;

  position: absolute;
  ${({ isFirst, isMiddle, isThird, isDark, isHistory }) =>
    isFirst && !isHistory
      ? `margin-left: 3px;`
      : isFirst && isHistory
      ? `margin-left:0px;`
      : isMiddle && !isDark
      ? `margin-left: -100%;`
      : isThird
      ? `margin-left: -200%;`
      : null};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isDark }) =>
      isDark
        ? `width: 99.2%; margin-left: 0.7%;`
        : `width: 100%; margin-left:auto`};
    @keyframes swipeDown {
      from {
        height: 0%;
      }
      to {
        height: 200%;
      }
    }
    height: 200%;
    animation: swipeDown 0.5s;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ isDark }) =>
      isDark
        ? `width: 98.8%; margin-left: 1.1%;`
        : `width: 99.8%; margin-left: 0.1%;`};

    @keyframes swipeDown {
      from {
        height: 0%;
      }
      to {
        ${({ isHistory }) => (isHistory ? `height: 400%;` : `height:120%`)};
      }
    }
    ${({ isHistory }) => (isHistory ? `height: 400%;` : `height:120%`)};
    animation: swipeDown 0.5s;
  }
`;

const ContentContainer = styled.div<{
  isDark: boolean;
}>`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 43% 57%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
  }
`;

const StyledImg = styled(Img)<{ containImage: boolean }>`
  width: 100.6%;
  height: 100%;
  img {
    ${({ containImage }) =>
      containImage
        ? "object-fit: contain !important;"
        : "object-fit: cover; !important"};
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 100%;
    width: 99.2%;
    margin-left: 0.7%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 99%;
    margin-left: 1%;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: -1;
  background: linear-gradient(
    270deg,
    rgba(15, 23, 45, 0) 13.03%,
    #060c1c 75.83%
  );

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    background: linear-gradient(
      0deg,
      rgba(15, 23, 45, 0) 13.03%,
      #060c1c 75.83%
    );
  }
`;

const CustomBlockContentContainer = styled.div<{ isDark: boolean }>`
  h2,
  h3,
  h4 {
    font-family: "bold";
    ${({ isDark }) =>
      isDark ? `color: white;` : `color: ${colors.darkGrey}; opacity: 0.5;`};
  }

  p {
    ${({ isDark }) => (isDark ? `color: white;` : `color: black;`)};
  }
`;

function ExpandedTab(props: Props) {
  const {
    name,
    featureImage,
    index,
    children,
    creationDate,
    subText,
    description,
    displayStyle,
    ctaLink,
    doubleHeight,
    isHistory,
    id,
    datePeriod,
    containImage,
  } = props;

  const isFirst = index === 0 || index % 3 === 0;
  const isMiddle = index === 1 || index % 3 === 1;
  const isThird = index === 2 || index % 3 === 2;

  const isDark = displayStyle === "dark";

  return (
    <ExpandedContainer
      backgroundColor={isDark ? "navy" : "white"}
      isDark={isDark}
      isFirst={isFirst}
      isMiddle={isMiddle}
      isThird={isThird}
      zIndex={2}
      doubleHeight={doubleHeight}
      isHistory={isHistory}
    >
      <Container position="absolute" top="5%" left="2%" zIndex={3}>
        {children}
      </Container>
      <AnimationContainer animationType={animationTypes.fadeIn} delay={500}>
        <ContentContainer isDark={isDark}>
          <Container
            margin="auto"
            tabletMargin={isDark ? "25% auto 0px auto" : "10% auto 3% auto"}
            mobileMargin={isDark ? "28% auto auto auto" : "19% auto auto auto"}
            width="58%"
            mobileWidth="75%"
          >
            <H2
              opacity={isDark ? 1 : 0.14}
              fontFamily="extra-bold"
              fontSize={doubleHeight ? 17 : 50}
              mobileFontSize={isHistory ? 17 : 30}
              margin="0px"
              color={"darkGrey"}
              mobileLineHeight={isHistory ? 18 : 30}
            >
              {name?.toUpperCase()}
            </H2>
            {creationDate && (
              <H3
                fontSize={doubleHeight ? 14 : 20}
                mobileFontSize={12}
                mobileLineHeight={13}
                margin="5px 0px"
                color={isDark ? "white" : "darkGrey"}
                opacity={isDark ? 1 : 0.55}
              >
                {creationDate}
              </H3>
            )}
            {description && (
              <P
                fontSize={14}
                lineHeight={20}
                margin="0px"
                color={isDark ? "white" : "darkGrey"}
              >
                {description}
              </P>
            )}
            {isHistory && (
              <ShareButton isDark={isDark} id={id} category={datePeriod} />
            )}

            {subText && (
              <CustomBlockContentContainer isDark={isDark}>
                <BlockContent blocks={subText._rawContent} />
                {ctaLink && ctaLink.linktext !== null && (
                  <IconButton
                    url={ctaLink.url}
                    buttonText={ctaLink.linktext}
                    darkVersion={!isDark}
                  />
                )}
              </CustomBlockContentContainer>
            )}
          </Container>
          {isDark ? (
            <>
              <Image
                backgroundStyle={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  objectFit: containImage ? "contain" : "cover",
                  top: 0,
                  zIndex: -1,
                }}
                fluid={featureImage?.asset?.fluid as FluidObject}
                isBackground
              />
              <Overlay />
            </>
          ) : (
            <StyledImg
              fluid={featureImage?.asset?.fluid as FluidObject}
              containImage={containImage}
            />
          )}
        </ContentContainer>
      </AnimationContainer>
    </ExpandedContainer>
  );
}

export default ExpandedTab;
